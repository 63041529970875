<template>
  <div>
    <div style="background-color: #3185ff">
      <div
        style="
          text-align: left;
          color: white;
          padding-top: 20px;
          padding-left: 10px;
        "
      >
        <span style="font-size: 1.1rem">{{ msisdn }}</span>
        <!-- <span style="font-size: 1.1rem">({{ operator }})</span> -->
      </div>
      <div
        style="
          text-align: center;
          color: white;
          font-size: 1rem;
          padding-top: 10px;
        "
      >
        剩余语音量
      </div>
      <div
        style="
          text-align: center;
          color: white;
          padding-top: 10px;
          font-size: 3rem;
        "
      >
        {{ usedInfo.residueVoice }}<span style="font-size: 2rem">分钟</span>
      </div>
      <div
        style="
          text-align: center;
          color: white;
          padding-top: 10px;
          padding-bottom: 10px;
        "
      >
        <span style="font-size: 1.2rem">本月总语音量</span>
        <span style="font-size: 1.7rem">{{ usedInfo.totalVoice }}</span
        >分钟
      </div>
    </div>
    <div style="padding: 10px">语音包推荐</div>
    <div
      v-for="(item, key) in addCombo"
      :key="key"
      class="content"
      @click="addComboChoose(item)"
    >
      <van-row
        :class="comboActive == item.comboId ? 'active' : ''"
        v-show="item.voice != 0"
      >
        <van-col span="18" style="padding: 10px">
          <span class="custom-title">{{ item.comboTitle }}</span
          ><br />
          <van-tag
            :type="tags[key]"
            style="margin: 3px"
            v-for="(tag, key) in item.tags"
            :key="key"
          >
            {{ tag }}
          </van-tag>
        </van-col>
        <van-col span="6" class="right-content">
          <div>
            <span>￥{{ item.currentPrice }}</span>
          </div>
        </van-col>
      </van-row>
    </div>
  </div>
  <div class="tips">
    <h4>温馨提示</h4>
      <p style="font-size: 10px;">1.本卡只在本公众号充值有效</p>
      <p style="font-size: 10px;">2.物联卡需充值和实名后方可使用</p>
      <p style="font-size: 10px;">3.网络延迟导致异常情况请尝试重新进入</p>
      <p style="font-size: 10px;">4.如遇问题请联系公众号客服处理</p>
  </div>
  <div style="height: 40px" />
  <common-footer />
  <!--弹出层，确认是否购买以及介绍套餐详情-->
  <van-popup
    v-model:show="show"
    position="bottom"
    :style="{ height: '70%' }"
  >
    <div style="position:sticky;top:0;background-color: white;z-index: 99;">
      <div style="height:10px;"></div>
      <van-card
        :tag="itemTag"
        :price="itemPrice"
        :desc="itemDesc"
        :title="itemTitle"
        thumb="/images/mobile.png"
        :origin-price="itemOriginPrice"
      />
    </div>
    <van-divider>语音包描述</van-divider>
    <div class="tips" style="white-space: pre-wrap">
      <p v-for="(tag, key) in this.goods.tags" :key="key">{{ tag }}</p>
      <p>{{ itemDesc }}</p>
    </div>
    <van-radio-group v-model="payType">
      <van-cell-group>
        <van-cell
          title="微信支付"
          icon="chat-o"
          clickable
          @click="payType = '1'"
        >
          <template #right-icon>
            <van-radio name="1" icon="passed" />
          </template>
        </van-cell>
        <van-cell title="余额支付" icon="paid" clickable @click="payType = '2'">
          <template #right-icon>
            <van-radio name="2" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>
    <van-submit-bar
      :price="payPrice"
      button-text="确认支付"
      decimal-length="2"
      :loading="loading"
      @submit="buyCombo"
    />
  </van-popup>
</template>

<script>
import commonFooter from "@/views/common/footer.vue";
import { getCombos, createOrder, selectCardUsed } from "@/api/card";
import wechatUtil from "@/utils/wechatUtil";
import { Dialog,Notify } from "vant";
import { calculateFlow } from "@/utils/convert";
import { parseTime } from "@/utils/index";
import Cookies from "js-cookie";
import "@/assets/combo.css";
export default {
  name: "flowAdd",
  components: {
    commonFooter,
  },
  data() {
    return {
      loading : false,
      msisdn: this.$store.getters.msisdn,
      iccid: "",
      operator: "",

      checked: true,
      addCombo: [],
      comboActive: 0,
      show: false,
      payType: "1",
      payPrice: 0,
      itemPrice: 0,
      itemOriginPrice: 0,
      itemTag: "推荐",
      itemTitle: "商品标题",
      itemDesc: "描述信息",
      showPicker: false,
      packTime: null,
      goods: null,
      tags: [
        "primary",
        "success",
        "danger",
        "warning",
        "primary",
        "success",
        "danger",
        "warning",
        "primary",
        "success",
        "danger",
        "warning",
      ],
      usedInfo: {
        residueVoice: "0",
        totalVoice: "0",
      },
    };
  },
  watch:{
    'show':'downShow'
  },
  created() {
    this.getComboList();
    this.getCardUsed();
    this.operator =
      this.$store.getters.corp === "1"
        ? "中国移动"
        : this.$store.getters.corp === "2"
        ? "中国联通"
        : "中国电信";
  },
  methods: {
    
    // 订单弹窗关闭时，按钮加载一起停止
    downShow(newer,older){
      if(!newer){
        this.loading = false
      }
    },
    getComboList() {
      getCombos().then(
        (res) => {
          for (var i = 0; i < this.getTagList(res[3]).length; i++) {
            var voice = this.getTagList(res[3])[i].voice;
            if (voice > 0) {
              this.addCombo.push(this.getTagList(res[3])[i]);
            }
          }
        }
      );
    },
    getTagList(combos) {
      
      for (let combo of combos) {
        if (combo.extendStr) {
            combo.tags = combo.extendStr.split(",");
        }
      }
      // for (let combo of combos) {
      //   if (combo.remark != null) {
      //     let remark = combo.remark.split("|");
      //     if (remark[0] != "") {
      //       combo.tags = remark[0].split(",");
      //     }
      //     combo.remark = remark[1];
      //   }
      // }
      return combos;
    },
    addComboChoose(item) {
      this.comboActive = item.comboId;
      this.goods = item;
      this.payPrice = item.currentPrice * 100;
      this.itemPrice = item.currentPrice;
      this.itemOriginPrice = item.originalPrice;
      this.itemTitle = item.comboTitle;
      this.itemDesc = item.remark == "null" ? "" : item.remark;
      this.show = true;
    },
    buyCombo() {
      this.loading = true;
      let user = JSON.parse(localStorage.getItem('user-info'))
      let appid = localStorage.getItem('resFlog1')
      let iccid = Cookies.get('card-id')
      createOrder(
        iccid||user.iccid,
        this.goods.groupId,
        this.goods.comboId,
        this.payType,
        appid||user.officialId,
        user.openid
      ).then((res) => {
        wechatUtil.init(["chooseWXPay"]).then((wx) => {
          this.loading = false;
          wx.chooseWXPay({
            timestamp: res.timestamp,
            nonceStr: res.nonce_str,
            package: res.prepay_id,
            signType: res.signType,
            paySign: res.sign,
            success:  () => {
                // 成功通知
                Notify({ type: 'success', message: '支付成功' });
                this.getCardUsed()
                this.show = false;
            },
            error:  () => {
                // 危险通知
                Notify({ type: 'danger', message: '取消支付' });
                this.getCardUsed()
                this.show = false;
            },
          });
          this.getCardUsed()
        });
      });
    },
    getCardUsed() {
      let iccid = Cookies.get('card-id');
      selectCardUsed(
        iccid,
        // parseTime(new Date(), "{y}{m}")
      ).then((res) => {
        if (res != null) {
          let cardUsed = calculateFlow(res);
          this.usedInfo = cardUsed;
        }
      });
    },
  },
};
</script>

<style>
</style>
